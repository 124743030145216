import { css, cx } from '@emotion/css';
import { useTheme2 } from '@grafana/ui';
import { DashboardDetails } from 'lib/grafana-api/fetch-dashboard-details';
import React, { useEffect, useState } from 'react';

type Props = {
    dashboardDetails: DashboardDetails
};

type Dashboard = {
    id: number,
    uid: string,
    title: string,
    description: string,
    tags: string[],
    folderTitle: string,
    url: string,
};

function dashboardFromDashboardDetails(dashboardDetails: DashboardDetails): Dashboard {
    return {
        id: dashboardDetails.dashboard.id,
        uid: dashboardDetails.dashboard.uid,
        title: dashboardDetails.dashboard.title,
        description: dashboardDetails.dashboard.description ?? "",
        tags: dashboardDetails.dashboard.tags ?? [],
        folderTitle: dashboardDetails.meta.folderTitle,
        url: dashboardDetails.meta.url,
    };
}

export const DashboardInfo: React.FC<Props> = ({ dashboardDetails }) => {
    const theme = useTheme2();
    const dashboard: Dashboard = dashboardFromDashboardDetails(dashboardDetails);
    const [description, setDescription] = useState<string>(dashboard?.description || "no description available");

    useEffect(() => {
        import('common-markdown').then(({ parse }) => {
            const markdown = parse(description);
            setDescription(markdown);
        });
    });

    return (
        <div className={cx(css(`margin: 5px 0;`))} title={JSON.stringify(dashboard, undefined, 2)}>
            <div className={cx(css(`
                color: ${theme.colors.text.secondary};
                box-sizing: border-box;
                padding: 7px;
                border: 1px solid ${theme.colors.border.weak};
                border-radius: 5px;
            `))}>
                <div>
                    <a href={dashboard.url} className={cx(css(`font-size: 1.1rem;:hover{text-decoration: underline;}`))}>{dashboard.title}</a>
                </div>
                <div dangerouslySetInnerHTML={{ __html: description }}/>
                <hr className={cx(css(`margin: 8px 0;`))}/>
                <div className={cx(css(`font-size: 0.8rem;`))}>
                    Tags: {JSON.stringify(dashboard.tags)}
                </div>
            </div>
        </div>
    );
}
