import { DashboardDetails, fetchDashboardDetails } from "./fetch-dashboard-details";

export type Dashboard = {
    id: number,
    uid: string,
    title: string,
    uri: string,
    url: string,
    slug: string,
    type: string,
    tags: string[],
    isStarred: boolean,
    sortMeta: number
};

export async function fetchDashboards(): Promise<DashboardDetails[]|undefined> {
    try {
        const response = await fetch("/api/search");
        if (response.status !== 200) {
            throw "failed to query all dashboards";
        }

        const dashboards = await response.json();
        const dashboardDetailsPromises: Array<Promise<DashboardDetails>> = [];
        for (let i = 0; i < dashboards.length; i++) {
            const dashboard: Dashboard = dashboards[i];
            dashboardDetailsPromises.push(fetchDashboardDetails(dashboard.uid));
        }
        const settledDashboardDetails = await Promise.allSettled(dashboardDetailsPromises);
        const dashboardDetails: DashboardDetails[] = [];
        const rejectedReasons = [];
        for (let i = 0; i < settledDashboardDetails.length; i++) {
            const settledDashboardDetail = settledDashboardDetails[i];
            switch (settledDashboardDetail.status) {
                case "rejected": {
                    rejectedReasons.push(settledDashboardDetail.reason);
                    break;
                }
                case "fulfilled": {
                    dashboardDetails.push(settledDashboardDetail.value);
                    break;
                }
                default: {
                    throw "unreachable";
                }
            };
        }
        if (rejectedReasons.length > 0) {
            throw `${rejectedReasons.length} calls to query dashboard details have been rejected: ${rejectedReasons.join(', ')}`
        }
        return dashboardDetails;
    } catch (err) {
        console.error(err);
        return undefined;
    }
}
