import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { DashboardPanelOptions } from 'types';
import { css, cx } from '@emotion/css';
import { fetchDashboards } from 'lib/grafana-api/fetch-dashboards';
import { DashboardDetails } from 'lib/grafana-api/fetch-dashboard-details';
import { DashboardInfo } from './DashboardInfo';
import { useTheme2 } from '@grafana/ui';

interface DashboardPanelProps extends PanelProps<DashboardPanelOptions> { }

export const DashboardPanel: React.FC<DashboardPanelProps> = ({ options, data, width, height }) => {
  const theme = useTheme2();
  const [dashboardFilter, setDashboardFilter] = useState<string>("");
  const [dashboardsDetails, setDashboardsDetails] = useState<DashboardDetails[] | undefined>(undefined);
  useEffect(() => {
    try {
      fetchDashboards()
        .then(dashboardDetails => {
          if (dashboardDetails) {
            dashboardDetails.sort(function (a, b) {
              if (a.dashboard.title > b.dashboard.title) {
                return 1;
              }
              if (a.dashboard.title < b.dashboard.title) {
                return -1;
              }
              return 0;
            });
            setDashboardsDetails(dashboardDetails);
          } else {
            console.warn("fetchDashboards returned `undefined`");
          }
        }, reason => {
          console.warn(`failed to resolve fetchDashboards: ${reason}`);
        });
    } catch (err) {
      console.warn(`fetchDashboards threw an exception: ${err}`);
    }
  }, []);

  return (
    <>
      <div className={cx(css(`
        position: relative;
        width: ${width}px;
        height: ${height}px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      `))}>

        <input type="text" placeholder="Filter dashboards..."
          onChange={event => {setDashboardFilter(event.target.value);}}
          className={cx(css(`
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding: 5px;
            margin-bottom: 5px;
            border: 1px solid ${theme.colors.border.weak};
            border-radius: 2px;
            outline: none;

            :hover, :focus {
              border: 1px solid ${theme.colors.border.strong};
            }
        `))}/>

        <div className={cx(css(`overflow: auto;`))}>
          <div className={cx(css(`
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              overflow-y: auto;
              padding: 5px;
              border: 1px solid ${theme.colors.border.weak};
          `))}>
            {dashboardsDetails && dashboardsDetails.map((dashboardDetails, index) => {
              if (appliesToFilter(dashboardDetails, dashboardFilter)) {
                return <DashboardInfo key={dashboardDetails.dashboard.id} dashboardDetails={dashboardDetails} />;
              }
              return undefined;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

function appliesToFilter(dashboardDetails: DashboardDetails, filter: string): boolean {
  filter = filter.toLowerCase();

  // partial match for title
  if (dashboardDetails.dashboard.title.toLowerCase().includes(filter)) {
    return true;
  }

  // partial match for description
  if (dashboardDetails.dashboard.description?.toLowerCase().includes(filter)) {
    return true;
  }

  // partial match for tags
  if (dashboardDetails.dashboard.tags) {
    for (let i = 0; i < dashboardDetails.dashboard.tags.length; i++) {
      if (dashboardDetails.dashboard.tags[i]?.toLowerCase().includes(filter)) {
        return true;
      }
    }
  }

  // exact match for uid
  if (dashboardDetails.dashboard.uid === filter) {
    return true;
  }

  const levenshtein = require('js-levenshtein');
  const required_levenshtein_distance = 3;
  let dist = 0;

  dist = levenshtein(dashboardDetails.dashboard.title, filter);
  if (dist <= required_levenshtein_distance) {
    return true;
  }

  if (dashboardDetails.dashboard.description) {
    dist = levenshtein(dashboardDetails.dashboard.description, filter);
    if (dist <= required_levenshtein_distance) {
      return true;
    }
  }

  if (dashboardDetails.dashboard.tags) {
    for (let i = 0; i < dashboardDetails.dashboard.tags.length; i++) {
      const tag = dashboardDetails.dashboard.tags[i];
      if (tag) {
        dist = levenshtein(tag, filter);
        if (dist <= required_levenshtein_distance) {
          return true;
        }
      }
    }
  }

  return false;
}
