export type DashboardDetails = {
    meta: {
        type: string,
        canSave: boolean,
        canEdit: boolean,
        canAdmin: boolean,
        canStar: boolean,
        canDelete: boolean,
        slug: string,
        url: string,
        expires: string,
        created: string,
        updated: string,
        updatedBy: string,
        createdBy: string,
        version: number,
        hasAcl: boolean,
        isFolder: boolean,
        folderId: number,
        folderUid: string,
        folderTitle: string,
        folderUrl: string,
        provisioned: boolean,
        provisionedExternalId: string,
        annotationsPermissions: {
            dashboard: {
                canAdd: boolean,
                canEdit: boolean,
                canDelete: boolean
            },
            organization: {
                canAdd: boolean,
                canEdit: boolean,
                canDelete: boolean
            }
        },
        publicDashboardAccessToken: string,
        publicDashboardUid: string,
        publicDashboardEnabled: boolean
    },
    dashboard: {
        description: string | undefined,
        annotations: {
            list: [
                {
                    builtIn: number,
                    datasource: {
                        type: string,
                        uid: string
                    },
                    enable: boolean,
                    hide: boolean,
                    iconColor: string,
                    name: string,
                    type: string
                }
            ]
        },
        editable: boolean,
        fiscalYearStartMonth: number,
        graphTooltip: number,
        id: number,
        links: any[],
        liveNow: boolean,
        panels: [
            {
                datasource: {
                    type: string,
                    uid: string
                },
                gridPos: {
                    h: number,
                    w: number,
                    x: number,
                    y: number
                },
                id: number,
                options: {
                    seriesCountSize: string,
                    showSeriesCount: string,
                    text: string
                },
                targets: [
                    {
                        datasource: {
                            type: string,
                            uid: string,
                        },
                        queryType: string,
                        refId: string
                    }
                ],
                title: string,
                type: string
            }
        ],
        refresh: string,
        schemaVersion: number,
        style: string,
        tags: string[],
        templating: {
            list: any[]
        },
        time: {
            from: string,
            to: string
        },
        timepicker: {},
        timezone: string,
        title: string,
        uid: string,
        version: number,
        weekStart: string
    }
};

export async function fetchDashboardDetails(uid: string): Promise<DashboardDetails> {
    const response = await fetch(`/api/dashboards/uid/${uid}`);
    if (response.status !== 200) {
        throw `failed to query details of dashboard: ${uid}`;
    }
    const dashboard = await response.json();
    return dashboard;
}
